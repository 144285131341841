@import '../../../general/scss/settings/variables.scss';

#spinner-backdrop {
    opacity: 0.8;
    filter: alpha(opacity=80);
    opacity: 0;
    transition: opacity 0.15s linear;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: $black;
    display: none;
}

div#spinner {
    display: none;
    width: 100px;
    height: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    background: url(images/loading.gif) no-repeat center $white;
    text-align: center;
    padding: 10px;
    font: normal 16px Tahoma, Geneva, sans-serif;
    border: 1px solid #666;
    margin-left: -50px;
    margin-top: -50px;
    z-index: 9999;
    overflow: auto;
    transition: opacity 0.15s linear;
    opacity: 0;
    filter: alpha(opacity=80);
}

.spinner{
    display: none;
    visibility: visible;
    width: 100px;
    height: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    background: url(images/loading.gif) no-repeat center $white;
    text-align: center;
    padding: 10px;
    font: normal 16px Tahoma, Geneva, sans-serif;
    border: 1px solid #666;
    margin-left: -50px;
    margin-top: -50px;
    overflow: auto;
    transition: opacity 0.15s linear;
    opacity: 0;
    z-index: 9999;
    filter: alpha(opacity=80);
}
.spinner-show{
    visibility: show;
    transition: opacity 0.15s linear;
    opacity: .8;
    display: block;
}

#tooltip {
    text-align: center;
    color: #fff;
    background-color: rgba(71, 68, 64, 1);
    background-image: url('images/footer-bg.png');
    position: absolute;
    padding: 15px;
    z-index: 9999;

    &:after {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid rgba(71, 68, 64, 1);
        content: '';
        position: absolute;
        left: 50%;
        bottom: -10px;
        margin-left: -10px;
    }

    &.top:after {
        border-top-color: transparent;
        border-bottom: 10px solid rgba(71, 68, 64, 1);
        top: -20px;
        bottom: auto;
    }

    &.left:after {
        left: 10px;
        margin: 0;
    }

    &.right:after {
        right: 10px;
        left: auto;
        margin: 0;
    }

    h2 {
        color: #fff;
        font-size: 1.4em;
    }
}

.loader {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    user-select: none;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    z-index: -1;
    transition: all .5s ease;

    &.show {
        z-index: 999;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=0);
    }
}

.loader-icon {
    background-image: url('images/loader-unipol.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 29px 29px;
    position: absolute;
    display: block;
    height: 29px;
    width: 29px;
    left: 50%;
    top: 50%;
    margin-left: -14.5px;
    margin-top: -14.5px;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 1s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
}

#modal-warning {
    h1 {
        color: $primary;
    }

    .modal-body {

        a,
        a:visited,
        a:hover,
        a:focus {
            color: $unipol-green;
        }

        p {
            padding: 10px 0 5px;
        }
    }

    .modal-footer {
        padding: 14px 15px 15px;
        margin-bottom: 0;
        text-align: center;
        box-shadow: inset 0 1px 0 $white;
        background-color: $white;
    }
}

.modal-header {
    .close {
        background-color: $grey;
    }
}

@include media-breakpoint-up(xl) {
    .modal-xl {
        max-width: 70%;
    }
}
